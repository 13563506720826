@import "_apx.scss";

* {
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  text-decoration: none;    
}

html,
body,
#root{
  
  width: 100%;
  min-height: 100%;
  margin: 0;
  padding: 0;
  font-family: Roboto;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  color: var(--color-text);
  scroll-behavior: smooth;

}

body { background: #1E2C39; }

p { color: var(--color-text); }

hr {

  width: 100%;
  height: $apx-2;
  margin: $apx-18 0;
  background: var(--tinkoff-border);
  border: unset;

}

.disabled{

  opacity: 0.4;
  pointer-events: none;
  
}

.error { 
  
  text-align: center;
  color: #F07B73; 

}

.fullscreen-tip {

  position: relative;
  display: flex;
  margin: auto;
  font-size: $apx-48;
  font-weight: 600;
  color: rgba(179, 220, 255, 0.05);
  align-content: center;
  justify-content: center;
  user-select: none;

}

.modal ~ .container,
.modal ~ #workspace,
.modal ~ header {

  filter: blur(2.5px);
  transition: 0.05s;

}

::placeholder { color: #43586B; }

::-webkit-scrollbar {
  width: $apx-4; /* ширина для вертикального скролла */
  height: $apx-4; /* высота для горизонтального скролла */
  background-color: #161E24;
}

::-webkit-scrollbar-thumb {
  background-color: #243442;
  border-radius: 9em;
  cursor: pointer;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #204956;
}

@import "_variables.scss";
@import "_tailwind.scss";
@import "_animations.scss";
@import "_light.scss";
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');