@import "../../../styles/_apx.scss";

.table {

  position: absolute;
  top: $apx-70;
  left: $apx-70;
  min-width: 299px;
  perspective: $apx-700;

  &:hover {

    z-index: 1500;
    
  }

  border-radius: $apx-27;
  background: #243442;
  box-shadow: $apx-0 $apx-1 $apx-23 $apx-3 #00000026;

  &__title, 
  .table__title input[type="text"] {

    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-width: 100%;
    height: 55px;
    font-family: Roboto Mono;
    font-size: 24px;
    font-weight: 500;
    color: #FFFFFF;
    border-radius: $apx-23 $apx-23 $apx-0 $apx-0; 

    input {

      margin-right: $apx-8 !important;
      position: relative;
      top: $apx-0;
      background: unset;
      text-align: center;
      border: unset;

      &:hover, &:focus { outline: none; }

    }

  }

  &__title {

    position: relative;
    text-align: center;
    padding: 0 65px 5px 65px;
    cursor: pointer;

    .dragTable, .saveNameAndColor {
      
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;

    }

    .dragTable {

      left: $apx-23;
      cursor: move;

    }

    .saveNameAndColor {

      top: -1px;
      width: 46px;
      height: 46px;
      right: 18px;
      border: $apx-6 solid #0000000d;
      background: #0000008c;
      border-radius: $apx-100;
      z-index: 3;

    }    

    .removeTable {

      opacity: 0;
      transition: 0.3s;
      position: absolute;
      top: $apx-7;
      width: 41px;
      height: 41px;
      padding: 9px;
      right: 9px;
      // border: $apx-0 solid #0000000d;
      background: #0000008c;
      border-radius: $apx-100;
      z-index: 2;
      
    }

    &:hover { .removeTable { opacity: 1 } }

  }

  &__debug {

    position: absolute;
    top: -2.4vw;
    font-size: 1.4vw;
    color: #3c769d;

  }

  .colorPlaceholder {

    position: absolute;
    z-index: 100;
    top: -14vw;
    left: 0; right: 0;
    margin: auto;

    .react-colorful { margin: auto; }
  }

  &__add {

    opacity: 0;
    transition: 0.2s;
    position: absolute;
    z-index: 3;
    bottom: -$apx-28;
    left: 0;
    right: 0;
    margin: auto;
    width: $apx-57;
    height: $apx-57;
    border-radius: $apx-100;
    background-color: #243442;
    // background-image: url(./icons/add.svg);
    // background-size: $apx-37;
    // background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;

    &--light { background-color: #2A3A49; }

  }

  .anchorIcon { transition: 0.3s; }

  &--form-is-opened &__add,
  &:hover &__add { opacity: 1; transition: 0s; }

  &:not(.table--form-is-opened).react-draggable-dragging {

    border: $apx-4 solid #00bcd4;
    .table__add { opacity: 0; }

  }

  &--form-is-opened {

    .anchorIcon { 
      
      path {fill: #F07B73;}
      transform: rotate(45deg);
    
    }

  }

}

.table__row {

  position: relative;
  z-index: 2;
  
  display: grid;
  grid-template-columns: 1fr 160px;

  align-items: center;
  background: #2A3A49;
  user-select: none;
  cursor: pointer;

  &:nth-child( 2n ) { background: #243442; }  

  &:first-child { padding-top: 3px; }

  &:last-child { 

    padding-bottom: 8px;
    border-radius: $apx-0 $apx-0 $apx-23 $apx-23; 

  }

  &_empty {

    justify-content: center;
    font-size: 20px;
    font-weight: 500;
    padding-bottom: 20px !important;
    height: 80px;

    span { opacity: 0.8; }

  }

  .table__col {

    font-size: 20px;
    padding: 10px 0 10px 27px;
    white-space: nowrap;

    &__name {

      position: relative;

      &__icon {

        position: absolute;
        left: 6px;
        top: 1px;
        bottom: 0;
        margin: auto;
        transition: 0.3s;

        &.primary {
          
          top: 0;
          path { stroke: #CEBF38; }
        
        }

      }

    }

    &__type { 
      
      overflow: hidden;
      text-overflow: ellipsis;
      color: #617B92; 
    
    }

  }

  &__btn {
    
    position: absolute;
    z-index: 1500;
    opacity: 0;
    transition: 0.3s;
    
    &-move {

      left: -0.4%;
      top: 0;
      bottom: 0.05%;
      margin: auto;
      cursor: n-resize;

    }

    &-remove {

      right: $apx-20;

    }

  }
  
  &:hover { 

    background: #274f5e;
    .table__row__btn { opacity: 1; }   


    .table__col__name__icon { 
      
      left: -30px; 

    }

  }

  &--flying {

    z-index: 1500;
    border: $apx-4 solid #00bcd4;
    border-radius: $apx-2 !important;
    .table__row__move { opacity: 1; }
  }

}

.table__form {

  $form-horizontal-padding: $apx-18;
  position: absolute;
  width: $apx-750;
  padding: $apx-20 $form-horizontal-padding $apx-12;
  border-radius: $apx-27;
  background: #243442;
  box-shadow: $apx-0 $apx-20 $apx-23 -$apx-5 #00000026;

  transform-origin: top;
  transition: 0.4s;
  transform: rotateX(-77deg) translateY($apx-16);
  opacity: 0;

  .textfield__title,
  .textarea__title,
  .selectfield__title 
  { font-size: $apx-20 !important; }

  .two-inputs {

    .selectfield {

      & + .selectfield { margin-left: $apx-16; }

    }

  }

  &__liner_x {

    width: calc( 100% + ($form-horizontal-padding * 2) );
    margin-left: -$form-horizontal-padding;
    height: $apx-2;
    background-color: #2C3E4F;

  }

  &__cutout {

    position: absolute;
    z-index: 1;
    top: -$apx-50;
    left: 0;
    right: 0;
    margin: auto;
    width: $apx-72;
    height: $apx-72;
    border-radius: $apx-100;
    background-color: #1e2c39;

  }

  &__content {

    z-index: 2000;
    position: relative;

  }

  &__col {

    width: 100%;
    max-width: 48.8%;

    &-2 {
      
      .selectfield__title { text-align: right; }

    }

  }

  &__checkboxs {

    padding-right: $apx-10;
    padding-bottom: $apx-1;

    &__col--1 {

      min-width: $apx-138;

    }

  }

  .textarea {

    width: 100%;
    max-width: unset;

    textarea {

      width: 100%;
      max-width: unset;

      padding: 0.156249vw 0.556249vw !important;

    }

  }

  .textfield__title,
  .selectfield__title,
  .textarea__title {

    font-size: 18px;
    font-weight: 400;

  }

}

.table--form-is-opened .table__form {
  
  opacity: 1;
  transform: rotateX(0deg) translateY($apx-14);

}

.dynamicArrows {   

  div {

    z-index: 1 !important;
    pointer-events: none !important;

    svg:hover {

      z-index: 100 !important;
      g { fill: #ffca00; }
      path:first-child { stroke: #ffca00; }

    }

  }
  
}

.constructor--tables-on-top-layer .dynamicArrows div { z-index: 0 !important; }

.tip_rename {

  flex-direction: row-reverse;
  width: 100%;
  top: $apx-20;
  right: -$apx-434;
  font-size: $apx-18;
  line-height: 100%;
  color: #2FBCB4;
  font-weight: 500;
  white-space: nowrap;
  opacity: 0.52;

  &__arrow {

    transform: rotate(90deg);
    $size: $apx-25;
    width: $size;
    min-width: $size;
    height: $size;
    top: $apx-3;
    left: -$apx-10;

  }

}