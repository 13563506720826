@import "../../../styles/_apx.scss";

.container {

  & + .container { margin-top: 0; }
  &_add { margin-top: $apx-20; }

}

.button {

  position: relative;
  top: -$apx-1;
  $size:    $apx-42 !important;
  min-width: $size;
  width:     $size;
  height:    $size;
  min-height:    $size;
  max-height:    $size;
  padding: 0 0 $apx-2 !important;
  font-size: $apx-38 !important;
  line-height: 0 !important;

  &_remove {
    padding: unset !important;
    background: #2C3C4B !important;
    border: unset !important;
  }

  .remove {

    position: absolute;
    width: 100% !important;
    height: 100% !important;
    top: 0;
    left: 0;
    right: 0 !important;
    padding-left: $apx-6 !important;
    background: unset !important;
  
  }

}

.email {
  color: #98c0e2 !important;
}

.selectfield {

  max-width: $apx-150;
  margin-left: $apx-8;
  margin-right: $apx-8;
  margin-bottom: unset !important;
  
  // &__field {
    
  //   background: #2C3C4B !important;
  //   border-radius: $apx-6 !important;
  //   border: unset !important;
  
  // }

  // &__list {
  //   background: rgb(30 40 51 / 99%) !important;
  // }

}