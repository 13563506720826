//импорт apx
@import "../../../styles/_apx.scss";

.tariff {

  user-select: none;
  top: -1vw;
  max-width: $apx-885;
  padding: 0 $apx-42 0 $apx-46;

  p { font-family: Roboto !important; }

  button {
  
    background: linear-gradient(0deg, #38874E, #38874E), #3084A8;
    border: $apx-1 solid #46BA5F;
    border-radius: $apx-6;
    padding: $apx-15;
    text-align: center;
    font-family: Roboto Mono;
    font-weight: 500;
    font-size: $apx-20;
    line-height: $apx-26;
    color: #FBFEFD;
    cursor: pointer;
    transition: 0.3s;

    &:hover {

      background: linear-gradient(0deg, #38874E, #38874E), #3084A8;
      border: $apx-1 solid #46BA5F;
      filter: brightness(1.15);
    
    }
  
  }

  &__title {
  
    font-family: Roboto;
    font-weight: 500;
    color: #6CD75B;
    font-size: $apx-40;
    line-height: $apx-46;
    padding: 0;
    margin: $apx-30 0 0;

    &-till {
    
      color: #B3DCFF;
      font-size: $apx-21;
      line-height: $apx-30;
    
    }
  
  }

  &__second_title {

    color: rgba(152, 212, 255, 0.47);
    margin-bottom: $apx-30;

    font-family: Roboto;
    font-size: $apx-21;
    font-weight: 500;
    line-height: normal;

  }

  &__subtitle {
  
    opacity: 0.25;
    font-weight: 500;
    color: #B3DCFF;
    font-size: $apx-24;
    line-height: $apx-28;
    text-align: center;
    position: relative;
    top: -$apx-3;
    white-space: nowrap;

    &_add {
    
      top: -$apx-1;
      left: -$apx-1;
      font-size: $apx-22;
      line-height: $apx-26;
    
    }
  
  }

  &__list__container {
  
    display: flex;
    padding: unset;
    flex-direction: column;

    &__content {
    
      display: flex;
      margin-bottom: -$apx-20;

      svg {
      
        width: $apx-28;
        height: $apx-28;
      
      }

      p {
      
        text-align: center;
        position: relative;
        top: -$apx-19;
        left: $apx-19;
        font-weight: 400;
        font-size: $apx-22;
        line-height: $apx-25;
        color: #B3DCFF;
      
      }
    
    }
  
  }

  &__respect {

    margin-top: 0;
    padding: $apx-10 0;
    border-radius: $apx-21;
    font-size: $apx-20;
    line-height: $apx-23;
    font-weight: 500;
    color: #60B7FF;
    text-shadow: none;
    background-color: #21303E;

    &__icon {

      &--ava {

        width: $apx-50;
        height: $apx-50;
        margin-right: $apx-21;
        border-radius: 100px;
        background-image: url(./icons/ava.png);

      }

      &--smile {

        width: $apx-32;
        height: $apx-31;
        margin: 0 $apx-11;
        background-image: url(./icons/smile.png);

      }

      &--money {

        width: $apx-32;
        height: $apx-31;
        margin-left: $apx-12;
        background-image: url(./icons/money.png);
        
      }

      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;

    }

  }

  &__buttons__container {
  
    display: flex;
    align-items: center;
    justify-content: flex-start;
    
    &__content {
    
      display: flex;
      flex-direction: column;
      padding-left: $apx-25;
      padding-right: $apx-25;

      &_left {
        
        width: 46%;
        margin-left: -$apx-23;
      
      }

      &_right {
        
        padding-top: 0.416664vw;
        padding-left: 2.4vw;
        padding-right: unset;

        .tariff__buttons__container__content__subtext {
          margin-top: -0.28vw;
          span { opacity: 0.75 !important; }
        }
      
      }

      &__text {

        margin-bottom: $apx-8;

        &-left {
      
          font-weight: 500;
          color: #B3DCFF;
          font-size: $apx-26;
          line-height: $apx-32;
          height: $apx-33;
        
        }

        &-right {
      
          font-weight: 500;
          color: #F3FF6D;
          font-size: $apx-22;
          line-height: $apx-26;
          height: $apx-33;
        
        }

      }

      &__subtext {

        margin: 0 0 $apx-20;
        padding: 0;
        font-size: $apx-20;
        line-height: $apx-23;
        font-weight: 500;
        color: #62B7FF;
        span { opacity: 0.65; }

      }
    
    }
  
  }

}