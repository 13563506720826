@import "../../../styles/_apx.scss";

.options {

  $radius: $apx-10;

  top: $apx-48;
  z-index: 2;
  position: absolute;
  right: $apx-0;
  padding: $apx-0;
  background: #15232f;
  border-radius: $radius;
  transition: 0.3s;
  transform-origin: top center;
  transform: scale(0);
  overflow: hidden;
  &.opened { transform: scale(1); }

  hr {
    margin: $apx-18 0 $apx-16;
    border-color: #0c0f15;
  }

  &__element {

    font-size: $apx-18;
    padding: $apx-11 $apx-20 $apx-11;
    border: $apx-1 solid #0c0f15;
    white-space: nowrap;

    // &:not(:last-child) { border-bottom: $apx-1 solid #0c0f15; }
    &:not(:last-child) { border-bottom: unset; }
    &:first-child { border-radius: $radius $radius 0 0; }
    &:last-child { border-radius: 0 0 $radius $radius; }

    &:hover {

      background: #1d3244;
      border: $apx-1 solid #2e4064;
      
    }

  }
 
}