@import "../../../styles/_apx.scss";

.selectfield {

  position: relative;
  width: 100%;
  max-width: $apx-382;
  margin-bottom: $apx-18;
  cursor: pointer;

  &__title {

    user-select: none;
    margin-bottom: $apx-13;
    padding-left: $apx-3;
    font-size: $apx-16;
    font-weight: 400;
    color: #769CBB;

    & ~ .selectfield__list { top: $apx-79 !important; }

  }

  &__field {

    position: relative;
    display: flex;
    align-items: center;
    padding: 0 $apx-16 $apx-3;
    width: 100%;
    height: $apx-44;
    font-size: $apx-19;
    color: #B2DCFF;
    border: $apx-1 solid #43586B;
    border-radius: $apx-8;

    &--placeholder { color: #43586B; }

    &__object {

      color: #616161;
      padding-left: $apx-6;

    }

    &__arrow {

      position: absolute;
      z-index: 2;
      top: $apx-0;
      bottom: 0;
      right: $apx-14;
      margin: auto;
      width: $apx-14;
      height: $apx-14;
      transition: 0.3s;
      background-image: url(./anchor.svg);

    }

  }

  &--opened { .selectfield__field__arrow { transform: rotate3d(1, 0, 0, 180deg ); } }

  &__list {

    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
    position: absolute;
    width: 98%;
    top: $apx-40;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 5;
    border-bottom-right-radius: $apx-8;
    border-bottom-left-radius: $apx-8;
    background: #263b4bba;

    &__item {

      cursor: pointer;
      padding: $apx-10 $apx-15 $apx-14;
      font-size: $apx-17;
      border-top: $apx-1 solid #304658;
      transition: 0.2s;

      &.disabled { color: #648cac; }

      &:hover {

        background: #2e4f6a;
        font-weight: 500;

      }

    }

  }

  ::placeholder {

    font-size: $apx-16;
    color: #4B6379;

  }

}

.flex:not(.flex-col) {

  .selectfield + .selectfield {

    margin-left: $apx-18;

  }

}

.popup .selectfield {

  max-width: unset;
  margin-bottom: $apx-18;
  
  &__field {

    padding: $apx-9 $apx-14 $apx-10;
    font-size: $apx-17;
    border-radius: $apx-10;

  }

  &__title { color: var(--color-popup-textgray); }

}

.modal {
  
  .selectfield{
    
    // &__field { background: rgba(0, 0, 0, 0.04); }
    &__list  { background: #263b4b; }
  
  }

}