:root {

  --red: #9F2A38;
  --green: #57BB4F;
  
  --text-red: #E35162;
  --text-green: #57BB4F;

  --color-text: #A6BDD5;
  --color-whitetext: #DBDBDB;
  --tinkoff-border: #2C3C4B;

}