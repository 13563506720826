@import "../../../styles/_apx.scss";

.button {

  position: absolute;
  $size: $apx-44;
  width: $size;
  min-width: $size;
  height: $size;
  display: grid;
  place-content: center;
  top: 0; bottom: 0;
  right: $apx-10;
  margin: auto;
  border-radius: $apx-13;
  background: #0C151E;
  outline: none;
  border: none;
  transition: 0.3s;

  svg {

    position: relative;
    top: -$apx-1;
    width: $apx-20;
    height: $apx-21;
    transition: 0.3s;

  }

  &:hover {

    svg {
      transform: scale(1.15);
    }

  }

}