@import "../../../styles/_apx.scss";

.scheme_row {

  cursor: pointer;
  width: 100%;
  height: $apx-119;
  padding: $apx-35 $apx-42;
  user-select: none;
  transition: 0.3s;
  color: #B2DCFF;
  background-color: #243442;  

  &:hover { background-color: #294358; }
  &:first-child { border-radius: $apx-27 $apx-27 0 0; }
  &:last-child { border-radius: 0 0 $apx-27 $apx-27; }
  & + .scheme_row { margin-top: $apx-4; }

  &__icon {

    top: -$apx-3; 
    width: $apx-59;
    height: $apx-50;

  }

  &__info { padding-left: $apx-30; }

  &__name {

    font-size: $apx-24;
    font-weight: 500;

  }

  &__description {

    font-size: $apx-18;
    font-weight: 500;
    margin-top: $apx-8;
    margin-bottom: $apx-8;

  }
  
}