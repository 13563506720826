@import "../../../styles/_apx.scss";

.toggleslider {

  width: 100%;
  user-select: none;

  &__title {

    font-size: $apx-16;
    font-weight: 400;
    color: #383838;
    margin-bottom: $apx-8;

  }

  &__container {

    width: 100%;
    height: $apx-51;
    margin-bottom: $apx-8;
    background-color: #2C3C4B;
    border-radius: $apx-7;
    cursor: pointer;

  }

  &__list {

    top: 0;
    width: 100%;
    height: 100%;

    z-index: 3;

    &__element {

      height: 100%;
      padding-bottom: $apx-4;
      font-size: $apx-17;
      line-height: $apx-19;
      font-weight: 500;
      color: #6F849B;

      &--selected {

        color: #B4D4F6;

      }

    }

  }

  &__anchor {

    z-index: 2;
    top: 0;
    height: 100%;
    background-color: #305679;
    border-radius: $apx-7;
    transition: 0.3s;

  }

}

// @media screen and (max-width: 900px) {

//   .toggleslider {
      
//     &__title {
  
//       font-size: $apx-m-16;
//       margin-bottom: $apx-m-8;
  
//     }
  
//     &__container {
  
//       height: $apx-m-46;
//       margin-bottom: $apx-m-8;
//       border-radius: $apx-m-7;
  
//     }
  
//     &__list {
    
//       &__element {
  
//         padding-bottom: $apx-m-4;
//         font-size: $apx-m-17;
//         line-height: $apx-m-19;
  
//       }
  
//     }
  
//     &__anchor {
  
//       border-radius: $apx-m-7;
  
//     }
  
//   }

// }