@import "../../../styles/_apx.scss";

.popup {

  &-container{

    padding: $apx-10 0;
    z-index: 20;
    display: flex;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    border-top: none;
    height: 100%;
    // max-height: calc(100% - 1.55em);
    overflow-x: hidden;
    overflow-y: auto;
    justify-content: center;
    align-items: center;

  }

  &-background {

    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    background: rgba(10, 8, 2, 0.25);
    z-index: 20;
    pointer-events: none;
  
  }

  max-width: 70vw;
  animation-name: zoomIn;
  animation-duration: 0.65s;
  animation-iteration-count: 1;
  position: relative;
  margin: auto .55em;
  z-index: 21;
  background: #243442;
  border: 2px solid #2C3C4B;
  box-shadow: 0px 2px 14px 1px rgba(0, 0, 0, 0.1);
  border-radius: $apx-14;
  user-select: none;

  &--without-background { background: unset; }
  
  &.zoomOut {

    animation-name: zoomOut;
    animation-duration: 0.65s;
    animation-iteration-count: 1;

  }

  &__row {

    padding: $apx-16 $apx-33;

    &.border-top { border-top: 2px solid #2C3C4B; }
    &.border-bottom { border-bottom: 2px solid #2C3C4B; }

  }

  &__title {

    font-family: Rubik !important;
    font-weight: 600;
    letter-spacing: $apx-1;
    word-spacing: $apx-8;
    font-size: $apx-24;
    line-height: $apx-45;
    text-align: center;
    color: #B3DCFF;
    padding: $apx-10 $apx-24 $apx-12;
    border-bottom: 2px solid #2C3C4B;

  }

  &__padding {

    font-family: Rubik !important;
    font-size: $apx-20;
    line-height: $apx-30;
    padding: $apx-16 $apx-18 $apx-18;

  }

  &__action_buttons {

    padding: $apx-10 $apx-12 $apx-12;
    border-top: 2px solid #2C3C4B;

    .button {

      min-width: $apx-240;
      padding-left: $apx-20;
      padding-right: $apx-20;
      flex-grow: 1;

    }

  }

}

// @media screen and (max-width: 900px) {

//   .popup {

//     &-container { padding: $apx-m-10 0; }

//     max-width: 96vw;
//     border-radius: 7px;

//     &__title {

//       font-size: $apx-m-24;
//       line-height: $apx-m-27;
//       padding: $apx-m-10 $apx-m-20 $apx-m-12;
  
//     }

//     &__row {

//       padding: $apx-m-10 $apx-m-8;
  
//       &.border-top { border-top: 2px solid #2C3C4B; }
//       &.border-bottom { border-bottom: 2px solid #2C3C4B; }
  
//     }
  
//     &__action_buttons {
  
//       flex-direction: column;
//       padding: $apx-24 $apx-33 $apx-40;
//       border-top: 2px solid #2C3C4B;
  
//       .button {
  
//         width: 100%;
//         min-width: $apx-240;
//         padding-left: $apx-20;
//         padding-right: $apx-20;

//         & + .button { margin-top: $apx-m-10; }
  
//       }
  
//     }

//   }

// }

@keyframes zoomIn {
  from {
    transform: scale(0.4) translateY(100vh);
    opacity: 0;
  }

  to {
    transform: scale(1) translateY(0);
    opacity: 1;
  }
}

@keyframes zoomOut {
  from {
    transform: scale(1) translateY(0);
    opacity: 1;
  }

  to {
    transform: scale(0.4) translateY(-100vh);
    opacity: 0;
  }
}