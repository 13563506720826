@import "../../../styles/_apx.scss";

.textfield {

  width: 100%;
  max-width: $apx-382;
  margin-bottom: $apx-18;

  &__title {

    user-select: none;
    margin-bottom: $apx-13;
    padding-left: $apx-3;
    font-size: $apx-16;
    font-weight: 400;
    color: #769CBB;

  }

  input {

    width: 100%;
    height: $apx-44;
    padding: 0 $apx-16 $apx-3;
    font-size: $apx-19;
    color: #B2DCFF;
    border: $apx-1 solid #43586B;
    background: unset;
    border-radius: $apx-8;

    &:hover,
    &:focus { outline: unset; }

  }

}

.flex > .textfield + .textfield { margin-left: $apx-16; }

.modal .textfield { max-width: unset; }

input[type="number"] {

  -moz-appearance: textfield;
  appearance: none;

}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

.searchIcon {

  position: absolute;
  pointer-events: none;
  top: 0;
  bottom: $apx-1;
  right: $apx-7;
  margin: auto;
  $size: $apx-32;
  width: $size;
  height: $size;

}