@import "../../../../../../styles/_apx.scss";

.relcolsel {

  .reltabsel__list {

    padding-left: $apx-6;
    
    &__el {

      margin-left: $apx-8;
      margin-bottom: $apx-36;
      font-size: $apx-23;
      line-height: 100%;

      svg {

        top: $apx-1;
        width: $apx-33;
        height: $apx-27;
        margin-right: $apx-18;

      }

      .relcolsel__choose_other_table {

        top: $apx-2;
        margin-left: $apx-14;
        font-size: $apx-20;
        line-height: $apx-24;
        color: #5F809C;

      }

    }
  
  }

}