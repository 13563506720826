@import "../../../styles/_apx.scss";

.schemes_container {

  width: 100%;
  max-width: $apx-898;
  margin: 0 auto;
  padding-top: $apx-40;

}

.schemes {

  &__head {

    perspective: $apx-1000;
    padding-right: $apx-6;

    &__title {

      font-size: $apx-48;
      font-weight: 600;
      color: rgba(179, 220, 255, 0.26);

    }

    .button_big_add { top: $apx-3; }

  }

  &__list {

    margin-top: $apx-42;
    margin-bottom: $apx-42;
    border-radius: $apx-27;
    overflow: hidden;

  }

}

.scheme_add {

  position: absolute;
  top: 0;
  right: -$apx-365;
  width: $apx-342;
  padding: $apx-27;
  border-radius: $apx-27;
  border: $apx-1 solid #455F74;
  background: #243442;
  transition: 0.3s;
  opacity: 0;
  transform-origin: left;
  transform: rotateY(65deg);

  &__title {

    user-select: none;
    font-size: $apx-33;
    font-weight: 700;
    margin-bottom: $apx-27;
    color: rgba(179, 220, 255, 0.45);

  }

  &__icon {

    position: absolute;
    top: $apx-24;
    right: $apx-15;
    width: $apx-59;
    height: $apx-50;

  }

}

.schemes--add {

  .scheme_add {
    
    opacity: 1;
    transform: rotateY(0deg);

  }

  .button_big_add svg { transform: rotate(135deg) scale(1.3); }

  .textarea textarea { min-height: $apx-80; }

}