
.container__main--light {

  $text-color-1: #3c5d89;
  $text-color-2: rgb(57, 57, 57);
  
  background: #fafafb;

  header { 
    
    background: #fff !important; 
    border-color: #00000027 !important;
    box-shadow: 2px -1px 20px rgba(29,42,68,.06);

    .logo {

      border-color: #0000000f !important;

      rect { stroke: $text-color-1 !important; }
      
      path { 

        &:not(path:last-child) { stroke: $text-color-1 !important; }
        &:last-child { fill: $text-color-1; }
        &:nth-child( 18 ) { 
        
          stroke: unset !important;
          fill: $text-color-1; 
        
        }
          
      }

    }

    .back-to-schemes {

      font-weight: 600 !important;
      color: $text-color-1 !important; 
      border-color: #0000000f !important;
      svg path { stroke: $text-color-1; }

    }

    .zoom_btns {
    
      border-color: #0000000f !important;
      svg path { fill: $text-color-1; }
    
    }

    .user_container {
        
      .user_name { 
      
        font-weight: 500 !important;
        color: $text-color-1 !important; 
      
      }

      .arrow svg path { fill: $text-color-1; }
    
    }

    .toggletwo {

      &__container {

        background-color: #eff0f2 !important;
        border-color: #d3dae1 !important;
        border: unset !important;

      }

      &__anchor {

        background-color: #fff !important;
        border-color: #f1f1f1 !important;
        border: 2px solid rgb(234, 234, 234) !important;

      }

      &.toggletheme .toggletwo__list__element {

        svg path { fill: #c0c8d1 !important; }

        &--selected {

          svg path { 
            
            &:nth-child(1) { 
              
              fill: rgb(240, 142, 18) !important; 
              stroke: rgb(240, 142, 18) !important; 
            
            }
          
          }

        }

      }

      &.togglemouse .toggletwo__list__element {

        svg path { stroke: #c0c8d1 !important; }

        &--selected {

          svg { 
            
            g { opacity: 1 !important; }
            path { stroke: #3c5d89 !important; }
          
          }

        }

      }

    }

    .tariffbar {

      &__content { color: $text-color-1 !important; }
      &__progress { 
        
        background: #efefef !important; 
        &__bar { background: #628ec7 !important; }
      
      }

    }
  
  }

  .schemes {

    &__head__title { color: rgb(173, 182, 203); }

    .button_big_add {

      color: #3c5d89 !important;
      svg path { fill: #3c5d89; }

    }

    &__list {
      
      box-shadow: 2px -1px 20px rgba(29,42,68,.06);

      .scheme_row {
      
        color: $text-color-1 !important;
        background-color: #ffffff !important;

        &__description { opacity: 0.65; }

        svg {
          
          opacity: 0.24;

          rect { 
            stroke: $text-color-1 !important; 
          }

          path { 

            fill: $text-color-1 !important;

            &:not(path:first-child) {
              stroke: $text-color-1 !important;  
            }

          }
        
        }

      }
    
    }

    .scheme_add {

      background: #fff !important;

      &__title { color: #3c5d89 !important; }

      &__icon {
          
        g { opacity: 0.3 !important; }

        rect { 
          stroke: $text-color-1 !important; 
        }

        path { 

          fill: $text-color-1 !important;

          &:not(path:first-child) {
            stroke: $text-color-1 !important;  
          }

        }
      
      }

    }

  }

  .constructor {

    background: unset !important;

    .button_big_add {

      color: #3c5d89 !important;
      background: #f0f0f0 !important;
      svg path { fill: #3c5d89; }

      
    }

    .table {

      background: #fff !important;
      box-shadow: -$apx-4 $apx-5 $apx-18 rgba(0, 0, 0, 0.103);

      &__row {

        background: #fff !important;
        border: unset !important;
        border-top: 1px solid #ececec !important;
        color: $text-color-2 !important;
        font-weight: 500 !important;
        
      }

      .table__col__name__icon {

        &.primary path { stroke: #f08e11; }

      }

      &__add { background: #fff !important; }

      &:not(.table--form-is-opened) .table__add svg path { fill: #62ad01 !important; }

      &__form {

        background: #fff !important;
        box-shadow: 0 $apx-5 $apx-18 rgba(0, 0, 0, 0.103);

        &__cutout { background: #eae9e7; }

        .table__add svg path { 
          
          &:first-child { fill: #62ad01 !important; }
          &:nth-child(2) { stroke: #62ad01 !important; }
        
        }

        .remove_relation { opacity: 0.7; &:hover { opacity: 1; } }

        .reltabsel__title { color: rgb(59, 59, 59) !important; }
        .table__form__liner_x { opacity: 0.07; }

        .reltabsel__list{
          &::-webkit-scrollbar {
            background-color: #dcdcdc !important;
            border: unset !important;
          }
          
          &::-webkit-scrollbar-thumb {
            background-color: #8599b6;
          }
          
          &::-webkit-scrollbar-thumb:hover {
            background-color: #7488a4;
          }
        }
        
        .reltabsel__list__el { 
          
          color: #2b6082;
          
          svg {

            path { stroke: #2b6082; }
            path:nth-child(2) { stroke: transparent !important; fill: #2b6082; }
          
          }
        
        }
        
      }

      .dtform {

        &__group { color: rgb(59, 59, 59) !important; font-weight: 600 !important; }
        &__type { color: #0e3d84 !important; }

      }

    }

  } 

  .dynamicArrows svg {
      
    path:first-child { stroke: #728595 !important; }
    g { fill: #728595; }

    &:hover {

      path:first-child { stroke: #43a9ff !important; }
      g { fill: #43a9ff !important; }

    }

  }

  .textfield__title,
  .selectfield__title,
  .textarea__title,
  .checkbox__title { color: #3e5767 !important; }

  .textfield input,
  textarea,
  .selectfield__field { 
    
    color: $text-color-2 !important; 
    border-color: #a8a8a8 !important;

    &::placeholder { color: rgb(183, 183, 183) !important; }
  
  }

  .selectfield__field--placeholder { color: rgb(183, 183, 183) !important;  }

  .selectfield {
    
    &__list {

      width: 100% !important;
      color: $text-color-2 !important; 
      background: #fffffff5;

      &__item { 
        
        border-color: #00000027 !important;
        &:first-child { border-top: unset !important; }
        &:hover { background: #ffdfc4; }
      
      }

    }

    &--opened .selectfield__list {

      border: 1px solid #00000027;
      border-top: unset;

    }

  }

  .options {

    color: $text-color-1;
    background: #fffffff5;


    &__element {

      border-color: #bbbbbb !important;
      &:hover { background: #e2f0ff; }

    }

  }
  
  .arrow-usermenu + .options {
    top: $apx-28 !important;
    border: $apx-2 solid #b4bfce;

    .options__element { border: unset !important;}
  }

  .checkbox {

    &__anchor { 
    
      transition: 0.3s;
      border-color: #a8a8a8; 
    
    }

    &--active {

      .checkbox__anchor { 
        
        background: #70c402;
        border-color: #62ad01;

        svg path { stroke: #fff; }
      
      }

    }

  }

  .radio__element {

    color: #485b67;

    &__anchor {

      background: #f7f7f7 !important;
      border-color: #D0D0D5 !important; 

    }

    &--selected {

      color: #485b67;
      
      .radio__element__anchor {

        div { 
          
          background: #70c402 !important;
          border-color: #62ad01 !important;
        
        }

      }

    }

  }

  .toggleslider {

    &__container {

      background-color: #eff0f2 !important;
      border-color: #d3dae1 !important;
      border: unset !important;

    }

    &__anchor {

      background-color: #5d9acd !important;
      border-color: #5d9acd !important;
      border: 2px solid rgb(234, 234, 234) !important;

    }

    &__list__element {

      position: relative;
      top: $apx-2;
      font-weight: 400;
      color: rgb(166, 166, 166);

      &--selected {

        color: #fff;
        font-weight: 400;

      }

    }

  }

  .button {
    
    &--blue {
  
      background: #5d9acd;
      border: $apx-1 solid #3A5E87;
      &:hover{ background: #285376; }
  
    }

    &--gray {

      color: #5e5e5e;
      background: #e9e9e9;
      border: $apx-1 solid #cccccc;
      &:hover{ background: #161f27; }
  
    }

  }

  .popup {

    &-background { background: rgba(10, 8, 2, 0.15) !important; }

    background: #fff;
    border: 2px solid #f4f4f4;
    box-shadow: 2px -1px 20px rgba(29,42,68,.06);

    &__action_buttons { border-color: #f4f4f4; }

    &__padding { color: $text-color-1; }

    &__title {

      color: $text-color-2;
      border-color: #f4f4f4;

    }

  }

  .share_email_row {
    
    .button {

      color: #03b243;
      background: unset !important;
      border: 1px solid #a8a8a8 !important;
      
      svg { 
        
        position: relative;
        left: -$apx-1;
      
      }

    }

  }

  .message_from_rikky {

    background-color: #fff !important;
    .tariff__respect { background-color: #fff !important; }

    &__image { border: unset !important; }

    &__content { 
      
      &__title { color: $text-color-1; }
      &__text { color: $text-color-1; }
      &__subtitle { 
        
        color: $text-color-2; 
        span { color: $text-color-2; }
        svg path { fill: $text-color-2; }
      
      }

    }

    .close_variants__var {
      color: $text-color-1 !important;
      border-color: #87abdc !important;
      background: #fff !important;
    }

  }

  .fullscreen-tip { color: #212c3617 !important; }

}