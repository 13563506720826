@import "../../../styles/_apx.scss";

.toggletwo {

  & + .user_container { margin-right: -40px; }

  width: $apx-104;
  user-select: none;

  &__container {

    width: 100%;
    height: $apx-40;
    background-color: #171D22;
    border: 0.076083vw solid #183443 !important;
    border-radius: $apx-8;
    cursor: pointer;

  }

  &__list {

    top: 0;
    width: 100%;
    height: 100%;

    z-index: 3;

    &__element {

      padding-bottom: $apx-4;

      &--selected {
        
        svg path {

          stroke: #3FA9A3;

        }
      
      }

      &--dark svg {

        position: relative;
        top: 0.04vw;
        min-width: $apx-24;
        width: $apx-24;
        height: $apx-24;

      }

      &--light svg {

        position: relative;
        top: 0.08vw;
        min-width: $apx-26;
        width: $apx-26;
        height: $apx-26;

      }

    }

  }

  &__anchor {

    z-index: 2;
    top: 0;
    height: 100%;
    background-color: #142433;
    border: 0.076083vw solid #255956 !important;
    border-radius: $apx-8;
    transition: 0.3s;

  }

}