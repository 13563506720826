@import "../../../styles/_apx.scss";

.checkbox {

  max-width: $apx-382;
  margin-bottom: $apx-18;

  &__anchor {

    width: $apx-31;
    height: $apx-30;

    margin-right: $apx-12;

    border: $apx-2 solid #43586B;
    border-radius: $apx-5;

    svg {

      transition: 0.25s;
      transform: scale( 0 );

    }

  }

  &--active svg { transform: scale( 1 ); }

  &__title {

    font-weight: 400;
    font-size: $apx-17;
    color: #769CBB;

  }

}