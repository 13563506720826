@import "../../../styles/_apx.scss";

.textarea {

  width: 100%;
  max-width: $apx-382;
  margin-bottom: $apx-22;

  &__title {

    user-select: none;
    margin-bottom: $apx-8;
    padding-left: $apx-3;
    font-size: $apx-16;
    font-weight: 300;
    color: #769CBB;

  }

  textarea {

    width: 100%;
    min-height: $apx-42;
    line-height: $apx-32;
    overflow: hidden;
    padding: $apx-9 $apx-9 $apx-9 $apx-14 !important;
    font-family: Roboto;
    font-size: $apx-19;
    color: #B2DCFF;
    background: unset;
    border: $apx-1 solid #43586B;
    border-radius: $apx-6;

    &:hover,
    &:focus { outline: unset; }

  }

}