@import "../../../styles/_apx.scss";

.tariffbar {

  width: 100%;
  max-width: $apx-851;
  margin: auto;
  user-select: none;

  &__content {

    position: relative;
    top: -1px;
    font-size: $apx-18;
    font-weight: 500;
    color: #2FBCB4;
    white-space: nowrap;

  }

  &__progress {

    width: 100%;
    height: 1vw;
    height: 0.9vw;
    background: #243544;
    border-radius: 100px;
    padding: $apx-5;
    margin: 0 $apx-20;

    &__bar {

      height: 100%;
      background: #2FBCB4;
      border-radius: 100px;

    }

  }

}