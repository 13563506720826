.popup_help {

  position: fixed;
  z-index: 2001;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-size: contain;
  background-color: #1E2C39;
  background-image: url('./quickguide.jpg');
  background-position: top left;

}