@import "../../../styles/_apx.scss";

header {

  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
  height: $apx-56;
  padding-left: $apx-15; 
  padding-right: $apx-20; 
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #1A2936;
  border-bottom: $apx-1 solid #2C3C4B;
  width: 100%;

  .header {

    &__aside {

      min-width: 23%;
      height: 100%;
      align-items: center;
      display: flex;

    }

    &__right { justify-content: flex-end; }

  }

  .logo {

    position: relative;
    width: 100%;
    max-width: $apx-200;
    min-height: 55%;
    max-height: 55%;
    margin-right: $apx-14; 
    border-right: $apx-2 solid rgba(47, 188, 180, 0.11);

  }

  .back-to-schemes {

    position: absolute;
    left: $apx-346;
    cursor: pointer;
    user-select: none;
    font-size: $apx-18;
    color: #2FBCB4;
    padding: 0 $apx-25;
    border-left: $apx-2 solid rgba(47, 188, 180, 0.11);
    border-right: $apx-2 solid rgba(47, 188, 180, 0.11);

    svg { 

      position: relative; 
      top: -$apx-2; 
      margin-right: $apx-12;

    }

  }

  .stats, .stats__item, .user_container{ display: flex; }

  .stats__item{ align-items: center;}

  .text{

    color: #A6BDD5;
    font-size: $apx-18;
    font-weight: 400;
  }

  .slash{

    border: $apx-1 solid #2C3C4B;
    width: $apx-28;
    margin-right: $apx-15;
    margin-left: $apx-15;
    transform: rotate( 90deg);
      
  }

  .user_container { 
    
    cursor: pointer; 
    user-select: none; 

    input {

      width: $apx-1;
      min-width: $apx-17;
      font-family: Roboto Mono;
      color: #2FBCB4;
      font-size: $apx-18;
      background: unset;
      border: unset;

      &:hover, &:focus { outline: none; }

    }
  
  }

  .user_name{

    font-family: Roboto Mono;
    color: #2FBCB4;
    font-size: $apx-18;

  }

  .number{ 
    
    font-weight: 500;
    margin-left: $apx-14;
    font-size: $apx-18;
  
  }

  .arrow{
    
    width: $apx-13;
    margin-left: $apx-17;
    transition: 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;
    
  }

  .zoom_btns {

    padding: 0 $apx-12;
    margin-right: $apx-20;  
    margin-left: 0.44vw;
    border-left: $apx-2 solid rgba(47, 188, 180, 0.11);
    border-right: $apx-2 solid rgba(47, 188, 180, 0.11);
  
    svg {
  
      cursor: pointer;
      width: $apx-26;
      height: $apx-26;
      transition: 0.3s;
  
      & + svg { margin-left: $apx-10; }
  
      &:hover { transform: scale(1.23); }
  
    }
    
  }

}